// @flow

import React, { useState, useContext } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { ImportantInformation } from '@latitude/important-information';
import { Box } from '@latitude/box';
import { AccordionSidebar } from '@latitude/accordion';
import { FeaturesSlider } from '@latitude/features-slider';
import { HorizontalRule } from '@latitude/horizontal-rule';
import { StickyCta } from '@latitude/sticky-cta';
import { Link } from '@latitude/link';
import { BrandedCallout } from '@latitude/callout';
import { Tel } from '@latitude/tel';
import { StickyNavigation } from '@latitude/sticky-navigation';
import { Heading4 } from '@latitude/heading';
import {
  BREAKPOINT,
  BUTTON_STYLE,
  COLOR,
  ALIGN
} from '@latitude/core/utils/constants';
import Layout from '../../components/layout';

import PageData from '../../data/pages/car-loans.json';
import footerData from '../../data/json/footer.json';

import Button from '../../components/Button/Button';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import './car-loans-page.scss';
import LoanRates from '../../components/LoanRates/LoanRates1';
import CalculatorTileCar from '../../components/Calculators/CalculatorTileCar';
import { featuresSliderIE11Fix } from '../../utils/stylesUtils';
import { LOAN_PURPOSE } from '../../utils/constants';
import EstimateRateWidgetSection from '../../components/EstimateRateWidgetSection/EstimateRateWidgetSection1';
import useApplyUrl from '../../hooks/useApplyUrl';
import SoftQuoteContext from '../../context/SoftQuoteContext';
import faqsLoans from './_faqs';
import HowToApplyEligibility from '../../components/HowToApplyEligibility/HowToApplyEligibility1';
import FeesAndCharges from './_LoanFeesCharges';

import heroImage from '../../images/hero-car-loan.webp';
import SvgInline from '@latitude/svg-inline';
import { PageContext } from '../../context/PageContext';
import HeroBanner from '@/components/lab-components/HeroBanner';
import PageHeaderSection from '@/components/PageLayout/PageHeaderSection';
import { getAccordionSidebarData } from "../../utils/helpers";

/*
 * Car Loan Page
 */
const CarLoanPage = () => {
  const [isNavSticky, setIsNavSticky] = useState(false);
  const { applyUrl, setApplyUrl } = useApplyUrl();

  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  const featureSliderData = state?.featureSliderData || [
    {
      heading: 'Why choose a Gem Loan?',
      featureCards: PageData.content.whyChooseLatitude
    }
  ];
  /** - END - */

  return (
    <Layout noMetaKeywords customFooter={footerData} hasStickyNav>
      <main className="navigation-spacer navigation-spacer--hasStickyNav car-loan-page">
        <Box backgroundColor={COLOR.GREY6}>
          <Helmet>
            <link
              rel="canonical"
              href="https://www.gemfinance.co.nz/loans/car-loans/"
            />
            <title>
              Car Loans NZ | Car Finance for your Next Vehicle | Gem by gem
              finance
            </title>
            <meta
              name="description"
              content="New or used car loan in New Zealand, achieve your goals with Gem Finance. Apply online today and enjoy flexible repayments.Get a quote in under 2 mins."
              noIndex
            />
          </Helmet>
          <Breadcrumb location={{ pathname: 'car-loans' }} />

          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="Car Loans"
              subTitle={
                <>
                  Whether you’re looking for a new or used car, motorbike or
                  caravan, we can help. Apply for a Gem Car Loan online in 7
                  minutes
                  <span class="conditional-text">
                    {' '}
                    and get a response to your application in only 60 seconds
                  </span>
                  .
                </>
              }
            >
              <div className="row text-white HeroContent__insettext">
                <div className="col-6 col-md-5 text-left">
                  <HomeButton
                    href={applyUrl}
                    trackId="personal-loans-button"
                    trackEventData={{
                      label: 'Get rate estimate',
                      location: 'Hero',
                      category: 'button-link'
                    }}
                    trackProductId={['PLNZLF-WEB']}
                    className="button--primary"
                  >
                    Get rate estimate
                  </HomeButton>
                </div>
                <div className="col-1 p-0 text-center">
                  <SvgInline name="stopwatch" />
                </div>
                <div className="col-5 col-md-6 text-left">
                  Check your interest rate in 2 minutes with no impact to your
                  credit score.
                </div>
              </div>
              <div className="row text-white HeroContent__insetdisclaimer">
                <div className="col-12 mt-2">
                  Credit and lending criteria, and fees apply.
                </div>
              </div>
            </PageHeaderSection>
          )}
          <SoftQuoteContext.Provider value={{ applyUrl, setApplyUrl }}>
            <div className="d-lg-none d-xl-none">
              <StickyCta
                href={applyUrl}
                trackId="sticky-cta--get-started"
                text="Get rate estimate"
              />
            </div>

            <div className="d-none d-lg-block">
              <StickyNavigation
                items={PageData.nav}
                phoneNumber={PageData.content.phoneNumber}
                isSticky={isNavSticky}
                ctaHref={applyUrl}
                ctaTarget="_self"
                ctaText="Get rate estimate"
                onStickyNavStateChange={setIsNavSticky}
                offsetElem="[data-sticky-navigation-offset]"
                trackId="sticky-nav-get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  label: 'Get rate estimate',
                  location: 'Sticky Navigation'
                }}
                trackProductId={['MLNZLF-WEB']}
                {...state?.inPageNavData?.[0]}
              />
            </div>

            <EstimateRateWidgetSection
              purpose={LOAN_PURPOSE.AUTO_PURCHASE}
              trackProductId={['MLNZLF-WEB']}
            />
          </SoftQuoteContext.Provider>
          <div
            css="
              position: relative;
              z-index: 1;
            "
          >
            <LoanRates
              isBranded
              rateBoxType="personalSym"
              rateBoxType2="personalUnsecuredSym"
              fees={PageData.content.loanDetails1}
              // hideInfoModal
            />
          </div>

          <FeesAndCharges data={PageData.content.fees} />

          <CalculatorTileCar />
          {featureSliderData[0] && (
            <FeaturesSlider
              id="why-us"
              responsiveSettings={PageData.whyChooseLatitudeResponsoveSettings}
              className="why-choose"
              heading={featureSliderData[0].heading}
              subheading={featureSliderData[0].description}
              data={featureSliderData[0].featureCards}
              css={`
                && {
                  background-color: #f8f8f8;
                  padding-top: 40px !important;
                  padding-bottom: 40px;

                  @media (min-width: ${BREAKPOINT.LG}) {
                    padding-top: 72px !important;
                    padding-bottom: 49px;
                  }
                }

                ${featuresSliderIE11Fix};
              `}
            />
          )}
          <HorizontalRule />

          <HowToApplyEligibility />

          <Box id="faq">
            <div data-contentful={state?.faqData?.[0]?.contentfulID}>
              <Box.Section>
                <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
                  {state?.faqData?.[0]?.title || 'Frequently asked questions'}
                </Heading4>
                <AccordionSidebar
                  data={getAccordionSidebarData(state?.faqData?.[0]?.data) || faqsLoans.loanCars1}
                />
              </Box.Section>
            </div>
          </Box>

          <BrandedCallout
            line1={
              <React.Fragment>
                <strong>
                  Got questions for our car loan team? Call now on{' '}
                  <Tel no="0800 422 898" color={COLOR.WHITE} />
                </strong>
              </React.Fragment>
            }
            line2={
              <React.Fragment>
                <span>Mon - Fri 9:00am - 6:30pm.</span>
              </React.Fragment>
            }
            cta={
              <Box width="210px">
                <Link
                  button={BUTTON_STYLE.PRIMARY}
                  href={applyUrl}
                  trackId="callout-get-started"
                  className="w-auto"
                  target="_self"
                  trackEventData={{
                    category: 'cta',
                    action: 'quote-link',
                    location: "We're here to help"
                  }}
                  trackProductId={['MLNZLF-WEB']}
                  style={{ marginTop: 24 }}
                >
                  Get rate estimate
                </Link>
              </Box>
            }
          />

          <ImportantInformation
            data={require('../../data/pages/car-loans.json')}
            sectionOneColummClasses="col-10 offset-1"
            {...state?.importantInfoData?.[0]}
          />
        </Box>
      </main>
    </Layout>
  );
};

const HomeButton = styled(Button)`
  && {
    padding: 15px 45px;
    background-color: #21a637;
    color: #fff !important;
    white-space: nowrap;
    font-size: 22px;
  }

  margin-bottom: 8px;
  &:hover {
    box-shadow: 0 1px 13px 0 rgba(0, 0, 0, 0.4);
    transition: all 150ms ease-in-out;
  }
`;

export default CarLoanPage;
